<template>
  <div class="mapping">
    <div class="mapping__content">
      <div class="mapping__wrapper">
        <VnSelect
          v-model="templateType"
          :disabled="true"
          :label="$t('Template')"
          :multi-cleanable="true"
          :options="templates"
          :readonly="readonly"
          class="mapping__elem"
          icon=""
          maxHeight="300px"
          textBy="name"
          :no-clear="true"
          width="200px"
        />
        <VnInput
          v-model="param"
          :placeholder="'Retailer SKU ID'"
          width="200px"
        />
        <div style="position: relative;" class="btns flex md6 xs12 xl12 n-pd">
          <va-button :style="{marginTop: 0, marginBottom: 0}" @click="updateSKU">Save</va-button>
          <IconRefresh
            v-if="checkForRefresh(retailer, retailerName)"
            class="mapping__update__reason" title="Update rich" @click="refreshLink"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/services/API/API";
import {showToastError, showToastSuccess} from "@/services/Helpers/HelperToast";

export default {
  name: "Mapping",
  components: {
    VnInput: () => import('../ui/vn-input'),
    VnSelect: () => import('../ui/vn-select'),
    IconRefresh: () => import('../ui/icons/IconRefresh')
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: ''
    },
    templateSelected: {
      type: String,
      default: () => ''
    },
    retailer: {
      type: Object,
      default: () => {
      },
    },
    retailerName: {
      type: String,
      default: ""
    }
  },
  created() {
    if (this.templateSelected === "") {
      if (this.retailer.group.name === "OZON")
        this.templateType = this.templates[1];
      else {
        this.templateType = this.templates[0];
      }
    } else {
      this.templateType = this.templates.filter(elem => {
        return elem.id === this.templateSelected
      })[0];
    }


    this.param = this.value
  },
  data() {
    return {
      templates: [
        {
          id: 'without_replacement',
          name: 'without_replacement',
        },
        {
          id: 'master_template-to-ozon_template',
          name: 'master_template-to-ozon_template',
        },
        {
          id: 'ozon_template-to-master_template',
          name: 'ozon_template-to-master_template'
        },
      ],
      templateType: {},
      param: ''
    }
  },

  methods: {
    updateSKU() {
      this.$emit('save', {
        data: {skuId: this.param, minisiteTemplateReplaceDirection: this.templateType.name},
        epackID: this.retailer.epackID,
        retailer: this.retailer.name
      })
    },
    refreshLink() {
      API.APIPut(`${process.env.VUE_APP_API_URL}/epackage/${this.retailer.epackID}/${this.retailer.id}/send-rich`,
        {},
        () => {
          showToastSuccess('Rich Refreshed', this.$toast)
        },
        error => showToastError(error?.response?.data?.code === 404 ? "Not found" : error?.response?.data?.error?.length ? error?.response?.data?.error: "Something wrong,<br/>please try later", this.$toast));
    },
    checkForRefresh(retailer, retailerName) {
      return retailerName?.search('ozon') !== -1 && retailerName !== 'ozon' || retailer.group?.name !== null && retailer?.group?.name?.search('AliExpress') !== -1
    }
  }
}
</script>

<style lang="scss" scoped>
.mapping {
  padding-top: 15px;

  &__update__reason {
    position: absolute;
    width: 24px;
    height: 24px;
    right: 80px;
    top: 20px;
    cursor: pointer;

    &:hover {
      svg {
        fill: black;
      }
    }
  }

  &__wrapper {
    width: 200px;
    display: flex;
    flex-direction: column;
  }

  &__elem {
    margin-bottom: 11px;
  }
}

.n-pd {
  padding-left: 0 !important;
}
</style>
